import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Contact Us",
  "slug": "contact-us",
  "displaySidebar": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="container position-relative">
      <div className="contact-us__content position-relative text-center">
        <h1 className="main-title">Questions & Feedback</h1>
        <div style={{
          "width": "60%",
          "margin": "0 auto",
          "fontSize": "1.25rem"
        }}>
          <p>Have any questions or feedback about our API Documentation website? We're always keen to hear feedback and happy to answer all your questions.</p>
          <p>Send us an email to <a href="mailto:api@enablehr.com">api@enablehr.com</a> and we'll get back to you soon.</p>
        </div>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      